import { compareVersions } from 'compare-versions';
interface PropsType {
  phpVersion: string;
  phpRequired?: string;
  wpVersion: string;
  wpRequired?: string;
}

const getSuspendReason = ({ phpVersion, phpRequired, wpRequired, wpVersion }: PropsType) => {
  const isPhpVersionValid = phpRequired && phpVersion ? compareVersions(phpVersion, phpRequired) >= 0 : true;
  const isWpVersionValid = wpRequired && wpVersion ? compareVersions(wpVersion, wpRequired) >= 0 : true;
  if (!isPhpVersionValid && !isWpVersionValid) {
    return 'php_and_wp';
  }
  if (!isPhpVersionValid) {
    return 'php';
  }
  if (!isWpVersionValid) {
    return 'wp';
  }
  return null;
};

export default getSuspendReason;
